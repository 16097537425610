
import {WOW} from '../../../node_modules/wowjs/dist/wow';
new WOW({
  offset: 90,
  mobile: false
}).init();

$(document).ready(function(){
  if($('.ny-nav').length > 0){
    let paddingLeft = $('.ny-nav-c').css("padding-left")
    let navLeft = $('.ny-nav a.active').offset().left - parseInt(paddingLeft.replace('px', ''))
    $('.ny-nav').scrollLeft(navLeft)
  }
})