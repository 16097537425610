import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('.header').removeClass('show-mob-nav')
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
  }else{
    $('.header').addClass('show-mob-nav')
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('header .menu-mask').fadeIn(200)
  }
})

$('.mob-header ul li .sub-nav a').on('click',function(){
  setTimeout(function(){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
  },200)
})

$('.mob-header ul li .title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

$('.header .nav .sub-nav a').on('click',function(){
  $('.header .nav .sub-nav').slideUp()
  setTimeout(function(){
    $('.header .bg').css('height','0px')
  },100)
  setTimeout(function(){
    $('.header').removeClass('show-bg')
  },300)
})

$('#header-search-open').on('click',function(){
  $('.header-search').addClass('active')
  $('#header-search-input').focus()
})
$('.header-search .header-search-close').on('click',function(){
  $('.header-search').removeClass('active')
  $('#header-search-input').blur()
})

var headermouseover_tid = [];
var headermouseout_tid = [];
var subNavH = 0

$(document).ready(function(){
  $('.mob-header ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })

  if(window.location.href.indexOf('#md') != -1){
    if($('.ny-banner').length > 0){
      let h = $('.ny-banner').outerHeight()
      $(document).scrollTop(h)
    }
  }

  if($(window).width() > 1024){
    $('.header .nav .sub-nav').each(function(index,item){
      if($(item).outerHeight() > subNavH){
        subNavH = $(item).outerHeight()
      }
    })
    $('.header .nav .sub-nav').css('height',subNavH +'px')
  
    //鼠标经过显示子菜单
    $(".header .nav").each(function (index) {
      $(this).hover(
        // 鼠标进入
        function () {
          var _self = this;
          // 停止卷起事件
          clearTimeout(headermouseout_tid[ index ]);
          // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
          headermouseover_tid[ index ] = setTimeout(function () {
            $('.header .nav .sub-nav').slideDown()
            $('.header').addClass('show-bg')
            $('.header .bg').css('height',subNavH +'px')
          }, 201);
        },
        // 鼠标离开
        function () {
          var _self = this;
          // 停止展开事件
          clearTimeout(headermouseover_tid[ index ]);
          // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
          headermouseout_tid[ index ] = setTimeout(function () {
            $('.header .nav .sub-nav').slideUp()
            setTimeout(function(){
              $('.header .bg').css('height','0px')
            },100)
            setTimeout(function(){
              $('.header').removeClass('show-bg')
            },300)
          }, 201);
      });
    });
  }
})

let beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if(scroH > 100){
    $('.header,.header-search').addClass('scroll')
  }else{
    $('.header,.header-search').removeClass('scroll')
  }

  if(scroH > 500){
    $('footer .backtop').addClass('active')
  }else{
    $('footer .backtop').removeClass('active')
  }

  //判断页面是上滚还是下滚
  let delta = scroH - beforeScroH;
  if (delta > 0 && scroH > 200) {
    $('.header').addClass('trans')
  }
  if (delta < 0) {
    $('.header').removeClass('trans')
  }

  beforeScroH = scroH;
});