module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="ny-banner">\r\n  <div class="bg-img bg-cover" style="background-image: url(' +
((__t = (require('../../assets/images/banner_about.jpg'))) == null ? '' : __t) +
');"></div>\r\n  <div class="ny-center container">\r\n    <h1 class="ny-banner-title">集团简介</h1>\r\n    <div class="crumbs">\r\n      <a href="#">首页</a><span>-</span>\r\n      <a href="#">企业概况</a><span>-</span>\r\n      <a href="#">集团介绍</a>\r\n    </div>\r\n  </div>\r\n  <div class="ny-nav-c container">\r\n    <div class="ny-nav">\r\n      <a href="about_introduction.html" class="active">集团介绍</a>\r\n      <a href="about_qualifications.html">公司资质</a>\r\n      <a href="about_culture.html">企业文化</a>\r\n      <a href="about_organization.html">组织结构</a>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}