import './index.scss'

$('footer .footer-nav ul li .title .iconfont').on('click',function(){
  let $parent = $(this).closest('li')
  if($parent.hasClass('active')){
    $parent.removeClass('active').find('.sub-nav').slideUp()
  }else{
    $parent.siblings().removeClass('active').find('.sub-nav').slideUp()
    $parent.addClass('active').find('.sub-nav').slideDown()
  }
})

$('footer .backtop').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {duration: 500,easing: "swing"});
  return false;
})

var c = document.getElementById('canv');
var cvtext = c.getContext('2d');

var w = c.width = window.innerWidth*1.2;
var h = c.height = window.innerHeight;

var draw = function(a, b, t) {
  cvtext.lineWidth = 0.8;
  cvtext.fillStyle = '#ecf6ff';
  cvtext.fillRect(0, 0, w, h);
  for (var i = -100; i < 100; i += 4) {
    // cvtext.strokeStyle = 'hsla(177, 95%, 15%, .15)';
    cvtext.strokeStyle = 'hsla(210, 50%, 49%, .1)';
    cvtext.beginPath();
    cvtext.moveTo(0, h / 2);
    for (var j = 0; j < w; j += 10) {
      cvtext.lineTo(10 * Math.sin(i / 10) +
        j + 0.008 * j * j,
        Math.floor(h / 2 + j / 2 *
          Math.sin(j / 50 - t / 50 - i / 118) +
          (i * 0.9) * Math.sin(j / 25 - (i + t) / 65)));
    };
    cvtext.stroke();
  }
}
var t = 0;

window.addEventListener('resize', function() {
  c.width = w = window.innerWidth;
  c.height = h = window.innerHeight;
  // cvtext.fillStyle = 'hsla(177, 95%, 55%, 1)';
  cvtext.fillStyle = 'hsla(204, 50%, 49%, 1)';
}, false);

var run = function() {
  window.requestAnimationFrame(run);
  t += 0.5;
  draw(33, 52 * Math.sin(t / 2400), t);
};
run();